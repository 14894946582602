import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  IonCol,
  IonGrid,
  IonPage,
  IonRow,
  IonIcon,
} from "@ionic/react";
import {
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonLabel,
} from "@ionic/react";
import { Route, Switch, Router } from "react-router-dom";
import Patientdashboard from "../patientdashboard/patientdashboard";
import Ecrf from "../ecrf/ecrf";
import Datasync from "../datasync/datasync";
import Patientlog from "../patientlog/patientlog";
import Messages from "../messages/messages";
import RegisterDevice from "../../components/RegisterDevice";
import RegisterPatient from "../../components/RegisterPatient";
import RegisterClinician from "../../components/RegisterClinician";
import AssignDevice from "../../components/AssignDevice";
import Revoke from "../../components/Revoke";
import { IonImg } from "@ionic/react";
import calyanlogo from "../../assets/logos/calyanLogo.svg";
import { dashOptions } from "../../utils/jsonFiles/dashOptions";
import { useAppData } from "../../context/appContext";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../../service/adminUI";
import moment from "moment";
import Report from "../report/report";
import "./dashboard.css";
import AppContext from "../../components/AppContext";
import * as _ from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { unescapeCRFData } from "../../util/helpers/helperfunctions";
import { IMPLANT_DATE_OF_VISIT } from "../../util/config";
import { handleLogout } from "../../service/adminUI";
import AdminDashboard from "../../components/AdminDashboard";

const selectedPatientSubject = new BehaviorSubject(null); //to emit patient selection event;

const Dashboard = () => {
  const appContextData = useAppData();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [message, setMessage] = useState("Loading..Please wait");
  const [toastClass, setToastClass] = useState("toast-invisible");
  const [toastVisible, setToastVisible] = useState(false);
  const MAIN_FOOTER_ENABLE_CSS = "homeMainFooter";
  const MAIN_FOOTER_DISABLE_CSS = "homeMainFooter-invisible";
  const TOAST_ERROR_CSS = "update-pwd-toast" + " " + "toast-error";
  const [mainFooterClass, setMainFooterClass] = useState(
    MAIN_FOOTER_DISABLE_CSS
  );
  const [registerToastVisible, setRegisterToastVisible] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("widget.png");
  const [registerRequestFormId, setRegisterRequestFormId] = useState("");
  const [cancelRequestFormId, setCancelRequestFormId] = useState("");
  const {
    dtalService,
    userData,
    refreshTimerId,
    appData,
    pageData,
    setPageData,
    setAppData,
    setRefreshTimerId,
  } = useContext(AppContext);
  const { userId } = userData;
  const isAdmin = userData.userType == "admin";

  const handleLogoutOnClick = async () => {
    const result = await handleLogout(
      userId,
      appData.refreshToken,
      appData.accessToken
    );
    if (result === 204) {
      //disable refresh token, clear context
      clearInterval(refreshTimerId.timerId);
      setRefreshTimerId({ timerId: null });
      setAppData({ count: 0, refreshToken: "", accessToken: "" });
      history.push("/login");
    } else {
      autoHideToast(TOAST_ERROR_CSS, "Error: " + result.error);
    }
  };

  //Auto Hide toast after 5 seconds
  const autoHideToast = (css, message) => {
    setToastClass(css);
    setMessage(message);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  const handleBckImgChange = (name) => {
    setBackgroundImage(name);
  };

  const handleFooterAction = (show) => {
    setMainFooterClass(
      show === true ? MAIN_FOOTER_ENABLE_CSS : MAIN_FOOTER_DISABLE_CSS
    );
  };

  const handlerCancelFormOnClick = () => {
    setCancelRequestFormId(pageData.formId);
  };

  const handleRegisterOnClick = (formId) => {
    setRegisterRequestFormId(pageData.formId);
  };

  const handleSetRegisterToast = (toastParams) => {
    setToastClass(toastParams.css);
    setMessage(toastParams.message);
    setRegisterToastVisible(true);
    setTimeout(() => {
      setRegisterToastVisible(false);
    }, 5000);
  };

  const resetRequest = () => {
    setRegisterRequestFormId("");
    setCancelRequestFormId("");
  };

  const getPatients = async () => {
    const devices = await dtalService.fetch("pacemaker");
    const patients = devices.items;
    console.log(patients, "patients");
    const filteredDevices = devices.items.filter((item, index) => {
      return item.attributes !== undefined;
    });
    console.log(filteredDevices, "filtered");
    for (var i = 0; i < filteredDevices?.length; i++) {
      let timestamp = await getSyncTimer(
        filteredDevices[i]?.attributes.subjectId
      );
      if (timestamp != undefined) {
        filteredDevices[i]["timestamp"] = moment
          .unix(timestamp)
          ._d.toString()
          .slice(4, 10);
      } else {
        filteredDevices[i]["timestamp"] = "N/A";
      }
      let symptoms = await getSymptoms(
        filteredDevices[i]?.attributes.subjectId
      );
      filteredDevices[i]["symptoms"] =
        symptoms[0]?.features?.content?.properties;

      let implantDate = await getImplantDate(
        filteredDevices[i]?.attributes.subjectId
      );
      filteredDevices[i]["implantDate"] = implantDate;
    }
    localStorage.setItem("patientData", JSON.stringify(filteredDevices));
    appContextData.setAllPatientData(filteredDevices);
    setPatientData(filteredDevices);
    setIsLoaded(true);
    //if(filteredDevices.length > 0) setSelectedPatient(filteredDevices[0].attributes.subjectId)
  };

  const getSyncTimer = async (subjectId, from, to) => {
    const syncTimer = await dtalService.get(`syncTimer:${subjectId}`);
    return syncTimer[0]?.features.content.properties.triggerTimeStamp;
  };

  const getSymptoms = async (subjectId, from, to) => {
    // const symptoms = await DTALSERVICE.subscribe(`symptom:${subjectId}`)
    const symptoms = await dtalService.get(`symptom:${subjectId}`);
    return symptoms;
  };

  const getImplantDate = async (subjectId) => {
    let implantDate = "";
    let eCRFData = await dtalService.get("eCRF_implant:" + subjectId);
    let implantData = eCRFData[0]?.features?.implant?.properties?.values;
    if (!_.isEmpty(implantData)) {
      implantData = unescapeCRFData(implantData);
      implantDate = implantData[IMPLANT_DATE_OF_VISIT].value;
    }
    return implantDate;
  };

  useEffect(() => {
    getPatients();
  }, []);

  const getuserInformation = async () => {
    let token = localStorage.getItem("access_token");
    const userInfo = await getUserInfo(token);
    setUserInfo(userInfo);
  };

  useEffect(() => {
    getuserInformation();
  }, []);

  const handlePatientChange = (e) => {
    //console.log(e.target.value,"this is the selecte patient")
    let updatedPatient = _.find(patientData, function (o) {
      return o.attributes.subjectId == e.target.value;
    });
    if (updatedPatient) {
      appContextData.setAppUserData(updatedPatient);
      selectedPatientSubject.next(updatedPatient.attributes.subjectId);
      //history.go(0); //refresh the current page
    }
  };

  return (
    <IonPage style={{ background: "#E6E6E6" }}>
      <IonGrid>
        <IonRow>
          <IonCol className="dashboard_sidebar_color" size="2.0">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "2.75rem",
                paddingTop: "2rem",
              }}
            >
              <IonImg
                style={{ width: "10rem" }}
                src={calyanlogo}
                alt="calyan logo"
              ></IonImg>
            </div>
            {dashOptions
              .filter((el) => {
                return (
                  el.role.find((role) => {
                    return role == userData.userType;
                  }) != null
                );
              })
              .map((item, index) => {
                return (
                  <div
                    data-testid={item.title}
                    key={index}
                    onClick={(e) => {
                      appContextData.setSelected(item.title);
                      history.push(`/dashboard/${item.route}`);
                    }}
                    className={
                      item.title == appContextData.selected
                        ? "selected"
                        : "unselected"
                    }
                  >
                    <div
                      className={
                        item.title == appContextData.selected
                          ? "logo_style_selected"
                          : "logo_style"
                      }
                    >
                      {item.logo}
                    </div>
                    <div
                      className={
                        item.title == appContextData.selected
                          ? "dashboard_options_text_selected"
                          : "dashboard_options_text"
                      }
                    >
                      {item.title}
                    </div>
                  </div>
                );
              })}
            <br />
            <div
              className="dashboard_options_text unselected"
              button
              onClick={handleLogoutOnClick}
              routerDirection="none"
            >
              <IonIcon
                className="ham-item-icon"
                src="../../assets/svg/logout.svg"
              />
              Logout
            </div>
          </IonCol>
          <IonCol
            style={{ padding: "20px", height: "100vh" }}
            className=""
            size="10"
          >
            {appContextData.selected != "eCRF" &&
              appContextData.selected != "Messages" &&
              !isAdmin && (
                <div style={{ display: "flex", paddingBottom: "40px" }}>
                  <IonList style={{ background: "#E6E6E6", width: "300px" }}>
                    <IonItem
                      className="item_background_color"
                      style={{ border: "1px solid #999999" }}
                    >
                      <IonItem data-testid="THOSPITALVAL">
                        {userInfo?.attributes.address[0]}
                      </IonItem>
                    </IonItem>
                  </IonList>

                  {patientData ? (
                    <IonList
                      style={{
                        background: "#E6E6E6",
                        width: "300px",
                        marginLeft: "25px",
                      }}
                    >
                      <IonItem
                        className="item_background_color"
                        style={{ border: "1px solid #999999" }}
                      >
                        <IonSelect
                          onIonChange={handlePatientChange}
                          data-testid="TPATIENTVAL"
                          placeholder="Select Patient"
                          value={
                            appContextData?.appUserData
                              ? appContextData.appUserData.attributes.subjectId
                              : patientData[0]?.attributes?.subjectId ?? null
                          }
                        >
                          {patientData?.map((item, index) => (
                            <IonSelectOption
                              key={index}
                              value={item.attributes.subjectId}
                            >
                              {item.attributes.subjectId}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  ) : null}
                </div>
              )}

            {/* {!isLoaded && <IonSpinner name="crescent"> </IonSpinner>} */}
            {!patientData.length && (
              <IonLabel
                data-testid="TDASHBOARDMESSAGE"
                value={"No patients found"}
              >
                {" "}
              </IonLabel>
            )}
            <Switch>
              <Route
                exact
                path="/dashboard/patientdashboard"
                render={() => <Patientdashboard patientData={patientData} />}
              />
              <Route
                exact
                path="/dashboard/home"
                render={() => <AdminDashboard />}
              />
              <Route exact path="/dashboard/ecrf" render={() => <Ecrf />} />
              <Route
                exact
                path="/dashboard/datasync"
                render={() => (
                  <Datasync selectedPatientSubject={selectedPatientSubject} />
                )}
              />
              <Route
                exact
                path="/dashboard/patientlog"
                render={() => (
                  <Patientlog selectedPatientSubject={selectedPatientSubject} />
                )}
              />
              <Route
                exact
                path="/dashboard/messages"
                render={() => <Messages />}
              />
              <Route exact path="/dashboard/report" render={() => <Report />} />
              <Route
                exact
                path="/dashboard/registerDevice"
                render={() => (
                  <RegisterDevice
                    text="Register Device"
                    bckimgChange={handleBckImgChange}
                    registerRequest={registerRequestFormId}
                    cancelRequest={cancelRequestFormId}
                    setRegisterToast={handleSetRegisterToast}
                    resetRequest={resetRequest}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/registerPatient"
                render={() => (
                  <RegisterPatient
                    text="Register Patient"
                    bckimgChange={handleBckImgChange}
                    registerRequest={registerRequestFormId}
                    cancelRequest={cancelRequestFormId}
                    setRegisterToast={handleSetRegisterToast}
                    resetRequest={resetRequest}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/registerClinician"
                render={() => (
                  <RegisterClinician
                    text="Register Clinician"
                    bckimgChange={handleBckImgChange}
                    registerRequest={registerRequestFormId}
                    cancelRequest={cancelRequestFormId}
                    setRegisterToast={handleSetRegisterToast}
                    resetRequest={resetRequest}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/assignDevice"
                render={() => (
                  <AssignDevice
                    text="Assign Device"
                    bckimgChange={handleBckImgChange}
                  />
                )}
              />
              <Route exact path="/dashboard/revoke">
                <Revoke text="Revoke" />
              </Route>
            </Switch>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default Dashboard;
