import React, { useState, useEffect, useRef } from 'react'
import { IonButton, IonCard, IonRow, IonSpinner, IonLabel } from '@ionic/react'
import 'react-circular-progressbar/dist/styles.css';
import { useAppData } from '../../context/appContext';
import { useHistory } from 'react-router';
// import Ecggraph from './components/ecggraph/ecggraph';
// import DTAL from '../../service/devicesubscription/mockdataservice';
import { DTAL } from '../../service/DTAL';
import { IoSyncSharp } from "react-icons/io5"
import moment from 'moment';
// import { Document, Page } from 'react-pdf';
import "./datasync.css"
//pdfme report creation
import { Template, generate, BLANK_PDF } from '@pdfme/generator';
import { pdftemplate, vpsvgstring, vssvgstring,IMPLANT_DATE_OF_VISIT } from '../../util/config';
import { Viewer } from "@pdfme/ui";
import * as _ from "lodash";
import { BehaviorSubject, Subject } from 'rxjs';
import * as domtoimage from 'dom-to-image';
import * as htmltoimage from 'html-to-image';
import { saveAs } from 'file-saver';


import { SciChartSurface } from "scichart";
import { NumericAxis } from "scichart/Charting/Visuals/Axis/NumericAxis";
import { EAutoRange } from "scichart/types/AutoRange";
import { NumberRange } from "scichart/Core/NumberRange";
import { XyDataSeries } from "scichart/Charting/Model/XyDataSeries";
import { FastLineRenderableSeries } from "scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries";
import { ENumericFormat } from "scichart/types/NumericFormat";
import { CustomAnnotation } from "scichart/Charting/Visuals/Annotations/CustomAnnotation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';

import {Line} from 'react-chartjs-2';

import vsImagesvg from "../../assets/vs.svg"
import vpImagesvg from "../../assets/vp.svg"

import { unescapeCRFData } from '../../util/helpers/helperfunctions';

const chartDivId = 'scichart-root';



const SIX_MONTHS_MS = 15552000000 //180*24*60*60*1000 (180 days)
const SSE_INACTIVITY_PERIOD = 5000;
const MAX_ECG_POINTS = 2000;
const COLOR_GREEN = "#00FF00";
const STROKE_THICKNESS = 2;
let selectedDatasynclog = null;
let selectedDeviceData = '' //device assoicated with the selected patient

let pdfviewerwindow = null;

let dtalService = new DTAL(null);

let deviceUpdates = new Map();
let isSSEstopped = true;
let datasynclogs = new Map();
let sessiondatasynclogs = new Map();
let subjectData = {};
let implantData = {};

let autoStartTimerId;
const duControlSubject = new BehaviorSubject(false); //to emit start processing of device updates;

const heightpercent = 0.15; //30% of window height
const widthpercent = 0.15;

export const scichartlicensekey = "ji8502UDsmOMmEkqz6NSsR+jR/brXRkjt7oHwB3qVe+4F4k05OyXRtxOloKAcTtJ8J7I6B4iIvLg7jbfixOlw0GAOAh0kUhmP8AoYTY7sNiQuJjX3m5dfgvWCTcyiGzqRCuvVbmtwTVM3tIwmniRwRRSCytqmkvuShNIoifYErjyKsDUz3s5LVrhR26zKblUwKmtjbpxA/v3z7henqiGeJdgxNezHkpWsMypMMVP+oRiLuEldR/cN/SbBu5kmBEUfhGrU9N3zFdzbzmom5VBhgfvHtfjuh5f5J8zYBr++r1oheI8ClqonkEUH/E70qVW7Vth0gvEqoELePkpps/0sl7JbFiUZdlxFXoDw28+tnjq1q8s/p7afsW1Xypx5Q027kkqLBzJ+u0TXgajSC/92eg3g52amzSYE/ldVw4n8up2jB/KSldctsTTgxabW2rvVoQKRlPYVvdy2/EC2/X30hFgVb0zcZSHeKHxM+dezN5bRjyNXJyDn/dH1lNXALBQV40YThuno9hiW9XK79bsRugS8aHmnpefenz3TWi1xwv5CBb2qEDjy7sn6JqAj7MIe5TKRwiuoWXEt1dIaOnWjjv0Y+3je6IsBhoWzpLS0IetTowSeQohudIFe3z6Gg+IhEg4kBRNtQ5fCRZQ6m7Ss8watyb0ioj7YiW5GdvQ1XE92v2s67ZayHXyx+9RSZ4=";

let lineChartData = {};
const vsImage = new Image().src={vsImagesvg};
const vpImage = new Image().src={vpImagesvg};


const drawExample = async (
  data: any, vsValues, vpValues
) => {


  SciChartSurface.setRuntimeLicenseKey(scichartlicensekey);
  const { sciChartSurface, wasmContext } = await SciChartSurface.create(chartDivId, {
    //widthAspect: window.innerWidth, heightAspect: calyanConstant.CHART_HEIGHT
    widthAspect: window.innerWidth * widthpercent, heightAspect: window.innerHeight * heightpercent,
  });
  const xAxis = new NumericAxis(wasmContext, {
    autoRange: EAutoRange.Once,
    // axisTitle: "Time(s)",
    // visibleRange: new NumberRange(0, calyanConstant.DEFAULT_EGM_XLENGTH), 
    autoTicks: false, majorDelta: 200, minorDelta: 25
  });
  xAxis.labelProvider.formatLabel = (dataValue, format) => {
    return dataValue / 200;  //200 samples per sec
  };
  // xAxis.visibleRangeLimit = new NumberRange(0, calyanConstant.DEFAULT_EGM_XLENGTH);

  const yAxis = new NumericAxis(wasmContext, {
    autoRange: EAutoRange.Always,
    growBy: new NumberRange(0.1, 0.1),
    axisTitle: "Voltage(mV)",
    axisTitleStyle: { fontSize: 16 },
    autoTicks: false,
    majorDelta: 0.2,
    minorDelta: 0.04,
    drawMajorTickLines: false,
    drawMajorGridLines: false,
    drawMinorGridLines: false,
    drawMinorTickLines: false,
    visibleRange: new NumberRange(13, 30)
  });
  //yAxis.labelProvider.numericFormat = ENumericFormat.Decimal;
  // yAxis.visibleRangeLimit = new NumberRange(13, 15);

  sciChartSurface.xAxes.add(xAxis);
  sciChartSurface.yAxes.add(yAxis);

  // Create and fill initial data series
  const dataSeries1 = new XyDataSeries(wasmContext);

  //check there was a previous draw that got switched
  if (data) {
    //append known points with annotation (if any)
    //let lastxIndex = currentPoint > POINTS_LOOP ? POINTS_LOOP:(currentPoint) % POINTS_LOOP;
    let lastxIndex = MAX_ECG_POINTS;

    for (let i = 0; i < lastxIndex; i++) {
      dataSeries1.append(i, data[i]);
      if (vsValues[i]) {
        let custannotation = new CustomAnnotation({
          x1: i,
          y1: data[i],
          // verticalAnchorPoint: EVerticalAnchorPoint.Top,
          // horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
          //TODO replace the svg for vs
          svgString: vssvgstring

        });
        //sciChartSurface.annotations.insert(xArr[i],
        sciChartSurface.annotations.add(custannotation);
      }

      if (vpValues[i]) {
        //sciChartSurface.annotations.set(xArr[i],
        let custannotation = new CustomAnnotation({
          x1: i,
          y1: data[i],
          // verticalAnchorPoint: EVerticalAnchorPoint.Top,
          // horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
          svgString: vpsvgstring

        });

        sciChartSurface.annotations.add(custannotation);

      }

    }


  }

  sciChartSurface.renderableSeries.add(
    new FastLineRenderableSeries(wasmContext, {
      strokeThickness: STROKE_THICKNESS,
      stroke: COLOR_GREEN,
      dataSeries: dataSeries1
    })
  );

  return sciChartSurface;
}
//plugin to fix the black background issue with chart exported jpeg image
const jpegPlugin = {
  id: 'jpegbackgroundfix',
  beforeDraw: function(chartInstance) {
    var ctx = chartInstance.ctx;
    ctx.save();
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, chartInstance.width, chartInstance.height);
    ctx.restore();
    }  
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  jpegPlugin
);



ChartJS.defaults.elements.line.borderWidth = 1


const Datasync = ({selectedPatientSubject}) => {


  const history = useHistory()
  const [userData, setUserData] = useState()
  const [batteryValue, setBatteryValue] = useState()
  const [syncData, setSyncData] = useState()
  const [syncDataDisplay, setSyncDataDisplay] = useState()
  const [indexValue, setIndexValue] = useState()
  const [selected, setSelected] = useState()
  const [report, setReport] = useState(false)  //to indicate availability of report
  const [isLoaded,setIsLoaded] = useState(false)
  const [isDataSyncAvailable,setIsDataSyncAvailable] = useState(true)  
  const [isLineChartDataAvailable,setIsLineChartDataAvailable] = useState(false)  



  const appData = useAppData()
  const chartRef = useRef(null);



  let stringPatientData = localStorage.getItem("patientData");
  let devices = JSON.parse(stringPatientData);
  let historcalSyncDataSubscription;



  const handlesseInactivity = () => {
    if (deviceUpdates.size) {
      duControlSubject.next(true)
    }

  };


  const getDataSync = async (deviceId) => {
    setIsLoaded(false);   
    //clear the previous data sync, close pdf viewer...
    deviceUpdates.clear();
    datasynclogs.clear();
    sessiondatasynclogs.clear()
    setSyncData([]);
    if(pdfviewerwindow) {
      pdfviewerwindow.close();
    }

    selectedDeviceData = _.find(devices, function (o) { return o.thingId == deviceId; });
    let currentDate = new Date();

    let totimestamp = currentDate.toISOString();
    let fromtimestamp = new Date(currentDate.getTime() - SIX_MONTHS_MS).toISOString();


    const historcalSyncData = await dtalService.gethistorical(selectedDeviceData.thingId, null, null, fromtimestamp, totimestamp, "thingId,attributes,features,_revision,_modified")
    if (historcalSyncData) {
      isSSEstopped = false; //SSE started
      historcalSyncDataSubscription = historcalSyncData.subscribe(response => {
        let existingEntry = null;

        //SSE
        if (autoStartTimerId) clearTimeout(autoStartTimerId); //cancel inactivity timer
        if (deviceUpdates.size==0) {
          deviceUpdates.set(response._modified, response);
          autoStartTimerId = setTimeout(handlesseInactivity, SSE_INACTIVITY_PERIOD); //start inactivity timer
        } else {
          existingEntry = deviceUpdates.get(response._modified);
          if (existingEntry) {
            //stop SSE as data is getting repeated, trigger processing of device updates
            duControlSubject.next(true);

          } else {
            //found new entry, start inactivity timer
            deviceUpdates.set(response._modified, response);
            autoStartTimerId = setTimeout(handlesseInactivity, SSE_INACTIVITY_PERIOD);

          }
        }
      });
    }

    //get subjectId DOB, implant date...
    let eCRFData = await dtalService.get("eCRF_subjects:"+selectedDeviceData.attributes.subjectId);
    subjectData = eCRFData[0];
    eCRFData = await dtalService.get("eCRF_implant:"+selectedDeviceData.attributes.subjectId);
    implantData = eCRFData[0]?.features?.implant?.properties?.values
    if(!_.isEmpty(implantData))
    implantData = unescapeCRFData(implantData)


  }



  useEffect(() => {
    // checkData()
    const selectedPatientSubscription = selectedPatientSubject.subscribe(selectedPatientId => {
      if(!selectedPatientId) {    
  
    appData.setSelected("Data Sync")
    if (appData?.appUserData == null) {
      getDataSync(devices[0]?.thingId)
    } else {
      getDataSync(appData?.appUserData.thingId)
    }} else {
      let device = _.find(devices,function (o) { return o.attributes.subjectId == selectedPatientId; });
      if(device) getDataSync(device.thingId)
    }})

    const duControlSubscription = duControlSubject.subscribe(isDUavailable => {
      if (isDUavailable) {
        //stop SSE
        dtalService.unsubscribe('history').then(resp => { isSSEstopped = true });    //acknowledge the promise on stopping SSE
        //process device updates
        let sseupdates = Array.from(deviceUpdates.values());
        //group based on day
        let duGroup = _.groupBy(sseupdates, ({ _modified }) => _modified.split('T')[0]) //_modified is always in ISO8601
        if (!_.isEmpty(duGroup)) {
          //iterate through the group
          _.forEachRight(duGroup, function (devupdates, day) {
            let dayMap = datasynclogs.get(day);
            if (!dayMap) {
              dayMap = new Map();
              datasynclogs.set(day, dayMap); //create datasynclog entry for the given day
              //dayMap.set('parameters', {}); //to store the latest parameter value for the given day
              dayMap.set('parameters', []); //to collect all the parameters updates for the given day
              dayMap.set('egm', []);
              dayMap.set('errors', []);
              dayMap.set('currentleadimpedance', []);
              dayMap.set('markers', []);
              //dayMap.set('vs', -1);
              dayMap.set('batteryvoltage', []);

            }
            //start from latest to old
            _.forEachRight(devupdates, function (du) {
              //store the latest parameters
              // if ((du.features?.deviceparameters?.properties) && _.isEmpty(dayMap.get('parameters'))) {
              //   dayMap.set('parameters', du.features.deviceparameters.properties);
              // }

              //store parameters with timestamp -> each entry indicate a session establishment wherein interrogation took place
              if ((du.features?.deviceparameters?.properties)) {
                let parametersample = {
                  parameterinfo: du.features.deviceparameters.properties,
                  ts: du._modified
                }
                let storedparametersamples = dayMap.get('parameters');
                storedparametersamples.push(parametersample);
                dayMap.set('parameters', storedparametersamples);                
              }              

              //store samples with timestamp
              if ((du.features?.egmstream?.properties.streams)) {
                if (!_.isEmpty(du.features.egmstream.properties.streams)) {
                  let streambuffer = {
                    streams: du.features.egmstream.properties.streams, //streams over 10 sec period
                    ts: du._modified
                  }
                  let storedsamples = dayMap.get('egm');
                  storedsamples.push(streambuffer);
                  dayMap.set('egm', storedsamples);

                }
              }

              //store the latest errors
              // if ((du.features?.devicestatus?.properties.errors?.processed) && _.isEmpty(dayMap.get('errors'))) {
              //   let errorinfo = {
              //     ts: du._modified,
              //     errcodes: du.features.devicestatus.properties.errors.processed
              //   }
              //   dayMap.set('errors', errorinfo);
              // }
              //store the errors with timestamp 
              if (du.features?.devicestatus?.properties.errors?.processed) {
                let errorinfo = {
                  ts: du._modified,
                  errcodes: du.features.devicestatus.properties.errors.processed
                }
                let storederrorsamples = dayMap.get('errors');
                storederrorsamples.push(errorinfo);
                dayMap.set('errors', storederrorsamples);
              }


              //store the latest battery voltage
              // if ((du.features?.devicestatus?.properties.batteryvoltage?.processed) && (dayMap.get('batteryvoltage') == -1)) {
              //   dayMap.set('batteryvoltage', du.features.devicestatus.properties.batteryvoltage.processed);
              // }
              //store the battery voltage with timestamp
               if (du.features?.devicestatus?.properties.batteryvoltage?.processed) {
                let batteryinfo = {
                  ts: du._modified,
                  batteryvalue: du.features.devicestatus.properties.batteryvoltage.processed
                }
                let storedbatterysamples = dayMap.get('batteryvoltage');
                storedbatterysamples.push(batteryinfo);                
                dayMap.set('batteryvoltage', storedbatterysamples);
               }              

              //store the latest currentleadimpedance
              // if ((du.features?.devicestoreddata?.properties.leadimpwkmin?.processed) && (dayMap.get('currentleadimpedance') == -1)) {
              //   dayMap.set('currentleadimpedance', du.features.devicestoreddata.properties.leadimpwkmin.processed[0]); //first element is the latest current impedance in weekly impedance values
              // }

              //store the currentleadimpedance with timestamp
              if (du.features?.devicestoreddata?.properties.leadimpwkmin?.processed) {
                let leadZinfo = {
                  ts: du._modified,
                  currentleadimpedance: du.features.devicestoreddata.properties.leadimpwkmin.processed[0] //first element is the latest current impedance in weekly impedance values
                }
                let storedZsamples = dayMap.get('currentleadimpedance');
                storedZsamples.push(leadZinfo);                              
                dayMap.set('currentleadimpedance', storedZsamples); 
              }

              // if ((du.features?.devicestoreddata?.properties.ventricularmarker?.processed) && (dayMap.get('vp') == -1)) {
              //   let markers = du.features.devicestoreddata.properties.ventricularmarker.processed.split(',');
              //   let vs = markers[0] ?? -1;
              //   let vp = markers[1] ?? -1;

              //   dayMap.set('vp', vp);//prepend + to convert to number
              //   dayMap.set('vs', vs);

              // }

              //store the vs, vp with timestamp
              if (du.features?.devicestoreddata?.properties.ventricularmarker?.processed) {
                let markers = du.features.devicestoreddata.properties.ventricularmarker.processed.split(',');
                let vs = markers[0] ?? -1;
                let vp = markers[1] ?? -1;
                let markerinfo = {
                  ts: du._modified,
                  vs,
                  vp
                }
                let storedmarkers = dayMap.get('markers');
                storedmarkers.push(markerinfo);
                dayMap.set('markers', storedmarkers);//prepend + to convert to number
              }              

            }
            );

            //segegrate into sessions based on parameters
            let sessionParameters = dayMap.get('parameters')
            let ainterrogatedts = [];            
            if(_.isEmpty(sessionParameters)) {
              //historical device updates didn't have any parameters
              //falling back to day basis
              let daySessionMap = new Map();
              sessiondatasynclogs.set(day, daySessionMap); //create datasynclog entry for the given session
              daySessionMap.set('parameters', null); //no parameters - 
              daySessionMap.set('egm', dayMap.get('egm'));
              let entity = dayMap.get('errors');
              daySessionMap.set('errors', _.isEmpty(entity)?null:entity[0]);
              entity = dayMap.get('currentleadimpedance');
              daySessionMap.set('currentleadimpedance', _.isEmpty(entity)?-1:entity[0].currentleadimpedance);
              entity = dayMap.get('markers');
              daySessionMap.set('vp', _.isEmpty(entity)?-1:entity[0].vp);
              daySessionMap.set('vs', _.isEmpty(entity)?-1:entity[0].vs);
              entity = dayMap.get('batteryvoltage');
              daySessionMap.set('batteryvoltage', _.isEmpty(entity)?-1:entity[0].batteryvalue);              
            } else {
              _.forEach(sessionParameters, function (parametersample) {
                let interrogatedts = parametersample.ts; ainterrogatedts.push(interrogatedts);
                let daySessionMap = sessiondatasynclogs.get(interrogatedts);
                if (!daySessionMap) {
                  daySessionMap = new Map();
                  sessiondatasynclogs.set(interrogatedts, daySessionMap); //create datasynclog entry for the given session
                  daySessionMap.set('parameters', parametersample.parameterinfo); //to store the parameter value for the given session
                  daySessionMap.set('egm', []);
                  daySessionMap.set('errors', {});
                  daySessionMap.set('currentleadimpedance', -1);
                  daySessionMap.set('vp', -1);
                  daySessionMap.set('vs', -1);
                  daySessionMap.set('batteryvoltage', -1);
                }
              })
              if(!_.isEmpty(ainterrogatedts))
              {
                ainterrogatedts.sort(); 

                let egm10secsamples = dayMap.get('egm');
                _.forEach(egm10secsamples, function (egm10secsample){
                  let interrogationIdx = _.sortedIndex(ainterrogatedts,egm10secsample.ts) - 1;
                  let daySessionMap = sessiondatasynclogs.get(ainterrogatedts[interrogationIdx]);
                  let storedsamples = daySessionMap.get('egm');
                  storedsamples.push(egm10secsample);
                  daySessionMap.set('egm', storedsamples);

                })

                let errorsamples = dayMap.get('errors');
                _.forEach(errorsamples, function (errorsample){
                  let interrogationIdx = _.sortedIndex(ainterrogatedts,errorsample.ts) - 1;
                  let daySessionMap = sessiondatasynclogs.get(ainterrogatedts[interrogationIdx]);
                  let errinfo = daySessionMap.get('errors');
                  if(_.isEmpty(errinfo)) daySessionMap.set('errors', errorsample); //set the latest error for the given session
                })

                let batteryVsamples = dayMap.get('batteryvoltage');
                _.forEach(batteryVsamples, function (batteryVsample){
                  let interrogationIdx = _.sortedIndex(ainterrogatedts,batteryVsample.ts) - 1;
                  let daySessionMap = sessiondatasynclogs.get(ainterrogatedts[interrogationIdx]);
                  let batteryV = daySessionMap.get('batteryvoltage');
                  if(batteryV == -1) daySessionMap.set('batteryvoltage', batteryVsample.batteryvalue); //set the latest batteryvoltage for the given session
                })

                let leadZsamples = dayMap.get('currentleadimpedance');
                _.forEach(leadZsamples, function (leadZsample){
                  let interrogationIdx = _.sortedIndex(ainterrogatedts,leadZsample.ts)  - 1;
                  let daySessionMap = sessiondatasynclogs.get(ainterrogatedts[interrogationIdx]);
                  let leadZV = daySessionMap.get('currentleadimpedance');
                  if(leadZV == -1) daySessionMap.set('currentleadimpedance', leadZsample.currentleadimpedance); //set the latest lead impedance for the given session
                })                

                let markersamples = dayMap.get('markers');
                _.forEach(markersamples, function (markersample){
                  let interrogationIdx = _.sortedIndex(ainterrogatedts,markersample.ts) - 1;
                  let daySessionMap = sessiondatasynclogs.get(ainterrogatedts[interrogationIdx]);
                  let vp = daySessionMap.get('vp');
                  let vs = daySessionMap.get('vs');
                  if(vp == -1) daySessionMap.set('vp', markersample.vp); //set the latest vp for the given session
                  if(vs == -1) daySessionMap.set('vs', markersample.vs); //set the latest vs for the given session
                })                
                
                
              }
            }
          }
          );

          

        }

        let days = Array.from(sessiondatasynclogs.keys())
        setSyncData(days)
        duControlSubject.next(false); //reset to enable next history of datasync
        setIsLoaded(true); //set to indicate the data is ready
        if(sessiondatasynclogs.size == 0) {
           setIsDataSyncAvailable(false)     
        } else {
          setIsDataSyncAvailable(true)
        }        

      }
    })

    return () => {
      duControlSubscription.unsubscribe();
      selectedPatientSubscription.unsubscribe();
      historcalSyncDataSubscription.unsubscribe();
      if (isSSEstopped == false) dtalService.unsubscribe('history').then(resp => { isSSEstopped = true });
    };

    // getDataSync()
  }, [])


  const drawLineChart = async (ydata,vs,vp, modifiedtime) => {
    setIsLineChartDataAvailable(false);
    lineChartData = {
      data: {
        datasets: [
          {
            //data: ydata.map((y,x) => ({x,y}))
            data: ydata,
            pointRadius: 0, //to reduce the cluttering of points,
            borderColor: '#000000',
            label: modifiedtime
            
          },
          {
            //data: ydata.map((y,x) => ({x,y}))
            data: ydata,
            //pointStyle: vs.map((value)=>  value ? vsImage: null),                
            pointStyle: vs.map((value)=> value ? 'triangle': 'cross'),
            pointRadius: vs.map((value)=> value ? 5: 0),
            borderColor: '#00FF00',
            label: 'Sensed'
            
          },
          {
            //data: ydata.map((y,x) => ({x,y}))
            data: ydata,
            pointStyle: vp.map((value)=> value ? 'triangle': 'cross'),        
            pointRadius: vp.map((value)=> value ? 5: 0),
            borderColor: '#0000FF',
            label: 'Paced'
            
          }
        ],
        labels: ydata.map((y,x) => x)
      },      
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
              max: 2000,
              min: 0,
              ticks: {
/*                   count: 10,
                  stepSize: 200 */
                  maxTicksLimit: 10,
                  sampleSize: 200,
                  major: false,
                  callback: function(value, index, ticks) {
                    return value/200;
                }                  

              },
              grid : {
                display: true,
                color: '#F00000', //color of grid line
/*                  tickBorderDash: function(context){
                  return context.tick.major ? null : [10, 4] //dash length, spacing
                  },  */
                  tickBorderDash: [10, 4],
              }
          } ,
          y: {
            position: 'right',
            title: 'mV',
            grid: {
              display: false
            }
        }          
      },        
/*         plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart'
          },
          beforeDraw: function (chart, easing) {
            var ctx = chart.chart.ctx;
            ctx.save();
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }          
        } */
      }
    }

    setIsLineChartDataAvailable(true);
  
  }

    const handlePreview = async (datasync, isPreview) => {
      console.log(datasync, "daatasync")
      // e.stopPropagation()
      //process the ecg streams
      selectedDatasynclog = sessiondatasynclogs.get(datasync);
      let astreambuffer = selectedDatasynclog.get('egm');
      let heartRateValues = [];
      let vsValues = [];
      let vpValues = [];
      let scichart;
      let decodedValues;
      let chartimages = [];
      let charttime = [];
      let dataUrl = "";
      let blob ="";

      setReport(false);
      setIsLineChartDataAvailable(false); //remove the last chart
      await new Promise((r) => setTimeout(r, 100)); //delay  
      blob = selectedDatasynclog.get("blob");
      if(blob) {
        //blob already generated, reuse and preview it
        setReport(true);
        await new Promise((r) => setTimeout(r, 100)); //delay  
        window.open(URL.createObjectURL(blob),"fpdfviewer"); 
      } else {
        //blob is not available, generate and preview it

      /*     _.forEach(astreambuffer, function(streambuffer) {
            _.forEach(streambuffer.streams,function(stream) {
              _.forEach(stream,function(sample) {
              decodedValues=sample.heartRateValues.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);  
              heartRateValues = _.concat(heartRateValues,decodedValues);
              decodedValues=sample.vs.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);           
              vsValues = _.concat(vsValues, decodedValues);
              decodedValues=sample.vp.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);        
              vpValues = _.concat(vpValues, decodedValues);
              })
            })
            
          }) */


      if (astreambuffer) {
        for (let iIdx = 0; iIdx < astreambuffer.length; iIdx++) {
          let streambuffer = astreambuffer[iIdx];
          heartRateValues = [];
          vsValues = [];
          vpValues = [];

          if (streambuffer && streambuffer.streams) {
            for (let iSecIdx = 0; iSecIdx < streambuffer.streams.length; iSecIdx++) {
              let stream = streambuffer.streams[iSecIdx];
              charttime.push(new Date(Date.parse(streambuffer.ts)).toLocaleString());
              if (stream) {
                for (let iMSecIdx = 0; iMSecIdx < stream.length; iMSecIdx++) {
                  let sample = stream[iMSecIdx];
                  if (sample) {
                    decodedValues = sample.heartRateValues.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);
                    heartRateValues = _.concat(heartRateValues, decodedValues);
                    decodedValues = sample.vs.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);
                    vsValues = _.concat(vsValues, decodedValues);
                    decodedValues = sample.vp.split(",").filter(x => x.trim().length && !isNaN(x)).map(Number);
                    vpValues = _.concat(vpValues, decodedValues);
                  }

                }
              }

            }
          }
          if (heartRateValues.length > 0) {
            //scichart = await drawExample(heartRateValues, vsValues, vpValues); //TODO commment if LineChart is used
            drawLineChart(heartRateValues,vsValues,vpValues,charttime[charttime.length-1]);
            await new Promise((r) => setTimeout(r, 500)); //delay to generate the image
            //dataUrl = await domtoimage.toPng(document.getElementById(chartDivId));
            //dataUrl = await domtoimage.toPng(chartRef.current);
            dataUrl = chartRef.current.toBase64Image('image/jpeg', 1)
            //dataUrl = chartRef.current.toBase64Image()
            chartimages.push(dataUrl);

            /*         domtoimage.toPng(document.getElementById(chartDivId))
                            .then(function(dataUrl: any) {
                              console.log(dataUrl,"dataurl");
                              chartimages.push(dataUrl)
                            })
                            .catch(function(error: any) {
                                console.error('oops, something went wrong!', error);
                            }); */

            // domtoimage.toBlob(document.getElementById(chartDivId)).then(function(blob: any) {
            //   console.log(blob,"blob")
            //           saveAs(blob, 'scichart.png');
            //       });       

            await new Promise((r) => setTimeout(r, 100)); //delay

            //scichart.delete();  //TODO comment if LineChart is used
          }

        }
      }

      let parameters = selectedDatasynclog.get("parameters");
      if(_.isEmpty(parameters)){
        parameters = selectedDeviceData.features?.deviceparameters?.properties;
      }
      let deviceerrorInfo = null;
      let deviceerrors = selectedDatasynclog.get("errors")
      if (!_.isEmpty(deviceerrors)) {
        let errorOccuredOn = new Date(Date.parse(deviceerrors.ts));
        deviceerrorInfo = errorOccuredOn.toDateString() + " " + deviceerrors.errcodes.join(',');

    }
      let syncdate = datasync.split('T')[0];



  

    const inputs = [
      {
        "PatientId": selectedDeviceData ? selectedDeviceData.attributes.subjectId : "DFU3287358",
        "DOB": !_.isEmpty(subjectData)?subjectData.attributes.dob:"06JUN1974",
        "Alerts": deviceerrorInfo ?? selectedDeviceData.features.devicestatus.properties?.errors?.processed?.errcodes?.join(',') ?? "No errors",
        "Patient indication":  "Sinus Node Dysfunction - AT/AF, Normal Conduction, NYHA Class I", //ZZZZ fetch symptoms,
        "model": selectedDeviceData ? selectedDeviceData.attributes.model : "calyan P3487",
        "evice type": "Single Chamber VVIR Pacemaker",
        "implant date": implantData?implantData[IMPLANT_DATE_OF_VISIT].value ?? "02DEC2019":"02DEC2019",
        "serial no":  selectedDeviceData ? selectedDeviceData.attributes.serialnumber : "C2834MA3R89W6",
        "Pacing mode": parameters?.pacingmode ?? selectedDeviceData.features.deviceparameters.properties.pacingmode,
        "last interrogation":  syncdate ?? "03MAR2023",
        "Current voltage": selectedDatasynclog.get("batteryvoltage") != -1? selectedDatasynclog.get("batteryvoltage") + "V" : "2.77V",
        "Current impedance": selectedDatasynclog.get("currentleadimpedance") != -1? selectedDatasynclog.get("currentleadimpedance") + "ohms" : "103 ohms",
        "meeasured threshold": "1.000 V at 0.40 ms",
        "date measured": syncdate ?? "03MAR2023",
        "programmed output": "2.00 V / 0.40 ms",
        "measured R-wave": "XX mv",
        "programmed sensetivity": parameters?.sensitivity + " mv" ?? selectedDeviceData.features.deviceparameters.properties.sensitivity + " mv",
        "refactory period": parameters?.refractoryperiod + " mSec" ?? selectedDeviceData.features.deviceparameters.properties.refractoryperiod + " mSec",
        "blanking period": parameters?.blankpostvs + " mSec" ?? selectedDeviceData.features.deviceparameters.properties.blankpostvs + " mSec",
        "measured impedance": "667 ohms",
        "ERI Voltage": "2.25V",
        "estimated longetivity": "8yrs/4mos",
        "parameter summary graph": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "flexarm impedance graph": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "interrogation date": syncdate ?? "03MAR2023",
        "device alerts":  deviceerrorInfo ?? "03 MAR 2023 12:43pm",
        "magnetic response": "Rate: @ 70 bpm",
        "pacing amplitude": parameters?.pacingamplitude + "V" ??  selectedDeviceData.features.deviceparameters.properties.pacingamplitude + "V",
        "pacing pulse width": parameters?.pacingpulsewidth + " mSec" ??  selectedDeviceData.features.deviceparameters.properties.pacingpulsewidth + " mSec",
        "blanking": parameters?.blankpostvs + " mSec" ?? selectedDeviceData.features.deviceparameters.properties.blankpostvs + " mSec",
        "sensitivity": parameters?.sensitivity + "mv" ?? selectedDeviceData.features.deviceparameters.properties.sensitivity + "mv",
        "mode": "VVIR",
        "rate": "84 bpm",
        "adaptive mode": "VVIR",
        "low activity rate": "84 bpm",
        "base rate": parameters?.pacingrate + " bpm" ??  selectedDeviceData.features.deviceparameters.properties.pacingrate + " bpm",
        "high activity rate": "130 bpm",
        "paced beats": "XXXXXXX",
        "paced beats percent": selectedDatasynclog.get("vs") != -1? selectedDatasynclog.get("vs") : "72.1%",
        "sensed beats": "XXXXXXX",
        "sensed beats percent": selectedDatasynclog.get("vp") != -1? selectedDatasynclog.get("vp") : "72.1%",
        "time1": "",//"06:11:38",
        "onset1": "",//"03 MAR 2023 12:36pm",
        "onset2": "",//"03 MAR 2023 12:36pm",
        "onset3": "",//"03 MAR 2023 12:36pm",
        "ventricular histogram graph": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "time2": "",//"03:06:40",
        "time3": "",//"00:11:42",
        "patient id": selectedDeviceData ? selectedDeviceData.attributes.subjectId : "DFU3287358",
        "graph1": chartimages[0] ?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "graph2": chartimages[1] ?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "graph3": chartimages[2] ?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "graph4": chartimages[3] ?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "graph5": chartimages[4] ?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAALuAQMAAADL0wGJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURbzAw+rv8fKruy0AAAPoSURBVHja7dwxbtwwEEBRCkKwRQodYftcYk+ROkcJz5NTsEuZK/AIKlIQAUEnke0VqQ0pA5zxWvFnZcD2s0CNuENxPOZBc0QDDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PD/+P8bkxvnTzzjTG0M2b5rh08rHNT518aPOnTt63+aGTd23edPJ2h//ax+/oO6Gzx6c78+cuPu7x01vmwx5/6uLnO/PjO+b/rGifvtWH3VnT9vmh/e3eqx/bc9d79af2YwEPDw8P/6r8r1GVt5VcUoZPtXxGhp9rGYEM72vbEBne1hJ5Gb6ayIvwqZppi/CxmgqL8KGaTIrwc3WDfATeV/ffIryr7r+PwNvq/vsIfH17D5/qbw/gb/mLKp/OqnycFPj14yqcJPm4vfp5VJ0cP6jyzqjy9rr2q/Dr4qzBp3V5E1wxp/V3rl8LXn32qF6fAY31Psv2NXi/5lQaH+Vuzak0eLsmPRppVPa3FPiU3QiFFDZmD4FCAh6yxV+Bn7O9isLmx2d/TGHr5rI7obDxtFkcKWyb89M2+U1/sX7Kv7Io8gaxFy5l2D/faRk++3x6PgsalV52+fwUW4j/+eGhDPuneyH/otHmp9jyfHHMLM6n4phZnI/FObA4H4qDWnF+Lo46xXlflG+I864o3xDnbVG+Ic6X9RXSfCrrK6T5WBZASPOhrFCQ5ufyjF+a92XdkjTvyrolad6WdUvS/KawSI7/sQn7JfDl+O+bsF8CX44fN2FvHnNnIT4Nm7BfAl+ON5uwXwJfjA/LCuk2BXvCvN0U7InxflmAtxV1gvx0U2N3luPtdZOlwj/FoR5vbuq85Pi48F6LD0scOk3+sq1cleP9ohlNfkpq/N9pGaMuH7T4ZVqGWYt/nBavyxstftblfaVIWJU/y72yODCfzJH5oMvPh+adLm+PzCdzZD5U/61ClT9Lnvyo8e7QvFHlE3ydj0zOu5ucickhcpgcJofJedUVk8j5b/nGgIeHh4d/I3x/RwLlhgfNH3DavSwGXf7YjT76uqCEQ/P37p9z7uKVmwspd17S7hul3PXK6fbsmnvWhO6GZlMnn3ri8gXN5GzHnX0B35ydj91814CHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHl+d/A9cKjmiL040TAAAAAElFTkSuQmCC",
        "graph-date1": charttime[0] ?? "2/08/2023, 1:47:57 PM",
        "graph-date2": charttime[1] ?? "2/08/2023, 1:47:57 PM",
        "graph-date3": charttime[2] ?? "2/08/2023, 1:47:57 PM",
        "graph-date4": charttime[3] ?? "2/08/2023, 1:47:57 PM",
        "graph-date5": charttime[4] ?? "2/08/2023, 1:47:57 PM"
      }
    ];

    await new Promise((r) => setTimeout(r, 100)); //delay 
    if(isPreview) {
      setReport(true);
  /*     const viewer = new Viewer({ domContainer:document.getElementById('pdfpreview'), template:pdftemplate, inputs }); */
      
      //print pdf
      const pdf = await generate({template: pdftemplate, inputs });
      // Browser
      blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      selectedDatasynclog.set("blob",blob) //save the blob for next use    
      pdfviewerwindow = window.open(URL.createObjectURL(blob),"fpdfviewer");  
      
    } else {
      //print pdf
      const pdf = await generate({template: pdftemplate, inputs });
      // Browser
      let blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      //window.open(URL.createObjectURL(blob));
      saveAs(blob, selectedDeviceData ? selectedDeviceData.attributes.subjectId : "DFU3287358" + 'Interrogation_Report.pdf');
    }
  } 
 };



  return (
    <div style={{ width: "100%", height: "75vh", display: "flex" }} className='datasync_container'>
      {/*     <IonRow>  */}
      {!isLoaded && <IonSpinner name="crescent"> </IonSpinner>}
      {!isDataSyncAvailable && <IonLabel value={"No data sync found for" + selectedDeviceData.attributes.subjectId}> </IonLabel>}      
      <div style={{ width: "40%", height: '100%', border: "1px solid #999999", borderRadius: '5px', padding: "15px", }}>
        <div style={{ display: "flex", justifyContent: 'space-between', paddingBottom: "15px" }}>
          <div style={{ fontSize: "14px", color: "#535352", paddingLeft: '20px' }}>SYNC LOG</div>
          <div style={{ fontSize: "24px", color: "#535352", paddingRight: "10px" }}><IoSyncSharp /></div>
        </div>

        <div style={{ display: "flex", justifyContent: 'center' }}>
          <div style={{ width: '100%', height: "60vh", border: "1px solid #999999", borderRadius: "5px", padding: "15px", overflowY: "scroll" }}>
            {syncData?.map((item, index) => {
              let localDate = new Date(item).toString();
              let localDateElements = localDate.split(' ');
              return (
                <div data-testid={"TDATASYNCCLICK_"+index} className={selected == item ? "datasync_log_container_selected" : "datasync_log_container"} onClick={(e) => {
                  setSelected(item)
                  setSyncDataDisplay(item)
                  setIndexValue(index)
                  handlePreview(item, true)
                }} key={index}>
                  <div>
                    <div data-testid={"TDATASYNCID_"+index} className='datasync_sync_id'>ID:{selectedDeviceData.attributes.subjectId} SYNC</div>
                    <div style={{ display: "flex" }}>
                      <div style={{ paddingRight: '20px', fontSize: "14px", color: "#535352" }}>
                        {localDateElements[4] + ' ' + localDateElements[2] +'/'+ localDateElements[1] +'/' + localDateElements[3] }
                      </div>
                    </div>
                  </div>
                  <div>
{/*                     {indexValue == index ? <IonButton onClick={(e) => handlePreview(item, false)} style={{ backgroundColor: "#22BCBD", letterSpacing: "0.2em" }}>Print/Save</IonButton> : null} */}
                    <IoSyncSharp style={{ fontSize: "24px", color: "#535352", marginLeft: "20px" }} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      </div>
{/*       {report? 
      <div style={{width:"55%",marginLeft:"15px",height:"100%",border:"1px solid #999999",padding:"15px",borderRadius:"5px"}}>
            <div id='container' style={{width:"100%",height:"100%",border:"1px solid #999999",borderRadius:"5px"}}>
            <div style={{height:"100%"}} id='pdfpreview' />
          </div>
      </div>
      :null} */}
      {report && <iframe data-testid="TIFRAME" id="fpdfpreview" name="fpdfviewer" src="about:blank" allow="" width="100%" height="100%"> </iframe>}
{/*     </IonRow>  
    <IonRow>   */}  
      {!report && 
      <div style={{width:"100%",height:"50%",display:"flex"}}>
{/*         <div id="scichart-root" style={{position: "relative"}}>
 */}        {isLineChartDataAvailable && <Line data={lineChartData.data} options={lineChartData.options}  ref={chartRef}/>}
{/*         </div> */}
      </div>}      
{/*       </IonRow>    */}
    </div>
  )
}

export default Datasync