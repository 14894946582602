import React, { useContext, useEffect, useState } from "react";
import "../styles/adminDashboard.css";
import {
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonButton,
  IonSpinner,
  IonPopover,
  IonList,
} from "@ionic/react";
import {
  closeCircle,
  filterOutline,
  optionsOutline,
  search,
} from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import PaginatedView from "./PaginatedView";
import UserTable from "./UserTable";
import SubjectIdTable from "./SubjectIdTable";
import DeviceTable from "./DeviceTable";
import {
  handleGrantPolicy,
  getAllDevices,
  getAllUsers,
  getFilteredDevices,
  getFilteredUsers,
  handleRevokePolicy,
  getAllSubjects,
  handleGrantThingPolicy,
  handlePolicyInfo,
} from "../service/adminUI";
import AppContext from "./AppContext";
import { useAppData } from "../context/appContext";

import * as _ from "lodash";

import { syncTimerFreqMode } from "../util/config";

const AdminDashboard = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [deviceResult, setDeviceResult] = useState([]);
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [isSubjectDataLoading, setIsSubjectDataLoading] = useState(false);
  const [isDeviceDataLoading, setIsDeviceDataLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    appData,
    userData,
    userFilterData,
    deviceFilterData,
    setUserFilterData,
    setDeviceFilterData,
    userRecords,
    deviceRecords,
    setUserRecords,
    setDeviceRecords,
    dtalService,
  } = useContext(AppContext);
  const MODEL_TYPE_USER = "user";
  const MODEL_TYPE_DEVICE = "device";
  const FILTER_CONDITION_ANY = "or";
  const FILTER_CONDITION_ALL = "and";
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");  
  const [userId, setUserId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [subject, setSubject] = useState("");
  const [patient, setPatient] = useState("");
  const [clinician, setClinician] = useState("");
  const [originalDeviceResult, setOriginalDeviceResult] = useState([]);
  const [subjectAsc, setSubjectAsc] = useState(true);
  const [patientAsc, setPatientAsc] = useState(true);
  const [serialNoAsc, setSerialNoAsc] = useState(true);
  const [clinicianAsc, setclinicianAsc] = useState(true);
  const [toastClass, setToastClass] = useState("toast toast-invisible");
  const [toastVisible, setToastVisible] = useState(false);
  const [message, setMessage] = useState("Loading..Please wait");
  const TOAST_SUCCESS_CSS = "toast toast-success";
  const TOAST_ERROR_CSS = "toast toast-error";
  const TOAST_INFO_CSS = "toast toast-info";


  const appContextData = useAppData();

  useEffect(async () => {
    appContextData.setSelected("Home");
    await handleLoadDevices();
  }, []);

  // useEffect(async () => {
  //     if (location.state) {
  //         const { modelType } = location.state;
  //         await handleLoadData(modelType);

  //         if (userRecords && userRecords.length > 0 && modelType != MODEL_TYPE_USER) {
  //             setUserResult(userRecords);
  //             setOriginalUserResult(userRecords);
  //         }
  //         if (deviceRecords && deviceRecords.length > 0 && modelType != MODEL_TYPE_DEVICE) {
  //             setDeviceResult(deviceRecords);
  //             setOriginalDeviceResult(deviceRecords);
  //         }
  //     }
  // }, [])

  const handleFilterOnClick = (modelName) => {
    history.push({ pathname: "/home/filter", state: { modelName } });
  };

  // const handleLoadData = async (modelType) => {
  //     if (modelType == MODEL_TYPE_USER) {
  //         await handleLoadUsers();
  //     } else if (modelType == MODEL_TYPE_DEVICE) {
  //         await handleLoadDevices();
  //     } else {
  //         await handleLoadUsers();
  //         await handleLoadDevices();
  //     }
  // }
 
  const handleLoadDevices = async (isStaleExpression) => {
    let deviceData = null;
    let newDeviceExpression = "";
    setIsDeviceDataLoading(true);
    if (isStaleExpression == true) {
      newDeviceExpression = setFilterExpression(MODEL_TYPE_DEVICE);
    } else {
      newDeviceExpression = deviceFilterData.deviceFilterExpression;
    }
    if (
      deviceFilterData.deviceFilters &&
      !(deviceFilterData.deviceFilters.length > 0)
    ) {
      deviceData = await getAllDevices(appData.accessToken);
    } else {
      //await getFilteredDevices(appData.accessToken, 'eq(attributes/serialnumber,"AAA089010AAB")');
      deviceData = await getFilteredDevices(
        appData.accessToken,
        newDeviceExpression
      );
    }
    const allUserPolicies = await getAllUserPolicies(deviceData);
    if (allUserPolicies) {
      setDeviceResult(allUserPolicies);
      setOriginalDeviceResult(allUserPolicies);
      setDeviceRecords(allUserPolicies);
    } else {
      setDeviceRecords([]);
      autoHideToast(TOAST_INFO_CSS, "Devices search: No data received");
    }
    setIsDeviceDataLoading(false);
  };

  const getAllUserPolicies = async(deviceData) => {
    const allUserPolicies = [];
    if (deviceData && deviceData.length > 0) {
      for(const data of deviceData) {
        const subjectId =
          data.attributes && data.attributes.subjectId
            ? data.attributes.subjectId
            : "";
        const serialnumber =
          data.attributes && data.attributes.serialnumber
            ? data.attributes.serialnumber
            : "";
        let patient = "";
        let clinician = "";
        if (data.policyId) {
          const policyInfo = await handlePolicyInfo(
            appData.accessToken,
            data.policyId
          );
          for (let val in policyInfo.entries) {
            console.log('val:::'+val)  
            let entry = policyInfo.entries[val]
            let entryObj = Object.values(entry.subjects)[0]
            console.log('entry:::'+entry.subjects)
            if (entryObj.type && entryObj.type === "patient"){
                patient = val;
               console.log('val:::'+val)  
            } 
            else if (entryObj.type && entryObj.type === "clinician") clinician = val;
          }
        }
        console.log('subjectId:::'+subjectId)
         allUserPolicies.push({ subjectId, serialnumber, patient, clinician });
      };
      console.log("allUserpolicies:::" + JSON.stringify(allUserPolicies));
    }

    return allUserPolicies;
  };

  const setFilterExpression = (model) => {
    let filterExpression = '';
    let filters = null;
    model == MODEL_TYPE_USER ? (filters = userFilterData.userFilters) : (filters = deviceFilterData.deviceFilters);

    //get all data when no filter selected
    if (filters == null || !(filters.length > 0)) {
        filterExpression = '';
    } else {
        if (filters.length == 1) {
            // no prefix for single expression
            filterExpression = filters[0].id;
        } else {
            for (let i = 0; i < filters.length; i++) {
                filterExpression = `${filterExpression}${filters[i].id},`;
            }
            filterExpression = filterExpression.slice(0, -1);
            if (model == MODEL_TYPE_USER) {
                //append and-or condition prefix in expression
                filterExpression = (userFilterData.userFilterExpression.startsWith(FILTER_CONDITION_ANY) ?
                    FILTER_CONDITION_ANY : FILTER_CONDITION_ALL) + `(${filterExpression})`;
            } else if (model == MODEL_TYPE_DEVICE) {
                //append and-or condition prefix in expression
                filterExpression = (deviceFilterData.deviceFilterExpression.startsWith(FILTER_CONDITION_ANY) ?
                    FILTER_CONDITION_ANY : FILTER_CONDITION_ALL) + `(${filterExpression})`;
            }
        }
    }
    //set app context for the filters and expressions
    model == MODEL_TYPE_USER ?
        (setUserFilterData({ ...userFilterData, userFilterExpression: filterExpression })) :
        (setDeviceFilterData({ ...deviceFilterData, deviceFilterExpression: filterExpression }));
    return filterExpression;
}
 
  const handleSubjectFilter = (event) => {
    const value = event.currentTarget.value;
    setSubject(value);
    const data = originalDeviceResult.filter(
      (user) =>
        (user.firstName &&
          user.firstName.toLowerCase().includes(value.toLowerCase())) ||
        (user.lastName &&
          user.lastName.toLowerCase().includes(value.toLowerCase()))
    );
    setDeviceResult(data);
  };

  const handlePatientFilter = (event) => {
    const value = event.currentTarget.value;
    setPatient(value);
    const data = originalDeviceResult.filter(
      (user) =>
        user.patient && user.patient.toLowerCase().includes(value.toLowerCase())
    );
    setDeviceResult(data);
  };

  const handleClinicianFilter = (event) => {
    const value = event.currentTarget.value;
    setClinician(value);
    const data = originalDeviceResult.filter(
      (user) =>
        user.clinician && user.clinician.toLowerCase().includes(value.toLowerCase())
    );
    setDeviceResult(data);
  };
  
  const handleSerialNoFilter = (event) => {
    const value = event.currentTarget.value;
    setSerialNo(value);
    const data = originalDeviceResult.filter((device) =>
      device.serialnumber.includes(value)
    );
    setDeviceResult(data);
  };
  const handleSerialNoSort = () => {
    let data;
    if (serialNoAsc) {
      data = deviceResult.sort((a, b) =>
        (a.subjectId).localeCompare(b.subjectId)
      );
    } else {
      data = deviceResult.sort((a, b) =>
        (b.subjectId).localeCompare(a.subjectId)
      );
    }
    setSerialNoAsc(!serialNoAsc);
    setDeviceResult([...data]);
  };
const handleSubjectSort = () => {
    let data;
    if (subjectAsc) {
      data = deviceResult.sort((a, b) =>
        (a.subjectId).localeCompare(b.subjectId)
      );
    } else {
      data = deviceRecords.sort((a, b) =>
        (b.subjectId).localeCompare(a.subjectId)
      );
    }
    setSubjectAsc(!subjectAsc);
    setDeviceResult([...data]);
  };

 const handlePatientSort = () => {
    let data;
    if (patientAsc) {
      data = deviceResult.sort((a, b) => a.patient.localeCompare(b.patient));
    } else {
      data = deviceResult.sort((a, b) => b.patient.localeCompare(a.patient));
    }
    setPatientAsc(!patientAsc);
    setDeviceResult([...data]);
  };

  const handleClinicianSort = () => {
    let data;
    if (clinicianAsc) {
      data = deviceResult.sort((a, b) =>
        a.clinician.localeCompare(b.clinician)
      );
    } else {
      data = deviceResult.sort((a, b) =>
        b.clinician.localeCompare(a.clinician)
      );
    }
    setclinicianAsc(!clinicianAsc);
    setDeviceResult([...data]);
  };
 

  //Auto Hide toast after 5 seconds
  const autoHideToast = (css, message) => {
    setToastClass(css);
    setMessage(message);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  return (
    <IonContent>
      <div className="search-box">
        <IonLabel className="table-heading">All Devices and Subjects</IonLabel>
      </div>

      <div>
        {isDeviceDataLoading ? (
          <div className="spinner-box">
            <IonSpinner name="lines" className="ion-spinner" />
            <IonLabel className="row-header">
              Please wait, search is in progress
            </IonLabel>
          </div>
        ) : (
          <IonGrid className="container-grid">
            <IonRow className="row-header">
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={serialNo}
                    placeholder="Serial Number"
                    onIonChange={handleSerialNoFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handleSerialNoSort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={subject}
                    placeholder="Subject"
                    onIonChange={handleSubjectFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handleSubjectSort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={patient}
                    placeholder="Patient Email"
                    onIonChange={handlePatientFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handlePatientSort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={clinician}
                    placeholder="Clinician Email"
                    onIonChange={handleClinicianFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handleClinicianSort}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <PaginatedView userData={deviceResult} pageSize = {20} >
              <IonList>
                {deviceResult.map((item) => {
                  return (
                    <IonRow className="devices-row">
                      <IonCol className="table-cell">
                        {item.serialnumber}
                      </IonCol>
                      <IonCol className="table-cell">{item.subjectId}</IonCol>
                      <IonCol className="table-cell">{item.patient}</IonCol>
                      <IonCol className="table-cell">{item.clinician}</IonCol>
                    </IonRow>
                  );
                })}
              </IonList>
            </PaginatedView>
          </IonGrid>
        )}
      </div>
    </IonContent>
  );
};
export default AdminDashboard;
